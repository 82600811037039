import { graphql } from 'gatsby';
import React, { FC } from 'react';

import SEO from 'src/components/Seo/Seo';
import Banner from 'src/components/Banner';
import { HeadingScope } from 'src/components/Heading';
import Main from 'src/components/Main';
import Gallery from 'src/components/Gallery';

interface GalleryPageProps {
  data: any;
}

const GalleryPage: FC<GalleryPageProps> = ({ data }) => {
  const {
    wpPage: { galleryFields: acfFields, seo },
  } = data;

  return (
    <HeadingScope>
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={seo.opengraphImage}
      />
      <Banner
        image={acfFields.headerImage.localFile.childImageSharp.gatsbyImageData}
        title={acfFields.title}
        subtitle={acfFields.subtitle}
      />
      <Main>
        <Gallery images={acfFields.gallery} />
      </Main>
    </HeadingScope>
  );
};

export const query = graphql`
  query Gallery {
    wpPage(slug: { eq: "galerija" }) {
      ...YoastSeoPage
      galleryFields {
        title
        subtitle
        headerImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        gallery {
          altText
          title
          description
          localFile {
            childImageSharp {
              thumb: gatsbyImageData(width: 270, height: 270)
              full: gatsbyImageData(width: 1024)
            }
          }
        }
      }
    }
  }
`;

export default GalleryPage;
